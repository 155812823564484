import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import RenderBlock from '../../components/blocks/RenderBlock'
import BlueHeader from '../../components/common/BlueHeader'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'

const TermsOfServicePage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='fr'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='fr'
    />

    <BlueHeader title={data.defaultPage.title} />

    <Container width={830}>
      <RenderBlock blocks={data.defaultPage.blocks} />
    </Container>

    <Footer />
    <Offers />
  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: {eq:"6792250d-8ba2-4571-a850-07c4acfcaf47"}, locale: {eq:"fr"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default TermsOfServicePage
